<template>
  <div id="taskPage" class="container-fluid min-100 d-flex flex-column">    
        <b-navbar toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand v-if="task != null">{{task.taskGrade}}: <strong>{{task.taskName}}</strong></b-navbar-brand>
            <b-navbar-nav class="ml-auto">
                <b-button variant="danger" class="mr-sm-4 mb-2 mb-sm-0" @click="$router.push({ name: 'Home'}).catch(() => {})" v-b-tooltip.html.hover="'Exit to Main Menu<br>without Saving'"><b-icon-dash-circle-fill class="mr-1"></b-icon-dash-circle-fill> Exit</b-button>
                <b-button id="submit-pdf" class="ml-sm-4" variant="info" v-b-tooltip.hover title="Submit your Work"><b-icon-cloud-arrow-up-fill class="mr-1"></b-icon-cloud-arrow-up-fill> &nbsp; Submit</b-button>
            </b-navbar-nav>
        </b-navbar>

        <b-modal id="formula-modal" title="Expression, Equation &amp; Formula Builder" size="lg" static hide-footer>
            <div class="alert alert-secondary" id="formula"></div>
            <div id="formula-error">You must enter a math expression before inserting.</div>
            <div><small>
                Build your formula above, select "Insert Formula" 
                <span v-if="!hasTouch">and then click the location on the page where you want the formula to be placed.</span>
                <span v-if="hasTouch"> then drag the formula from the middle of the page to where you want it placed.</span>
                <br>
                You may only place a formula on the page you are currently viewing.
            </small></div>
            <div class="row">
                <div class="col-4">
                <b-button class="mt-3" block @click="closeFormula">Cancel &amp; Close</b-button>
                </div>
                <div class="col">
                <b-button id="insert-formula" variant="primary" class="mt-3" block>Insert Formula</b-button>
                </div>
            </div>                    
        </b-modal> 

        <b-modal id="help-modal" title="Help" size="lg" class="text-left" static hide-footer>
            <h4 class="text-left">Important Note:</h4>
            <p>You must complete the entire task at one time.   Once submitted, you will not be able 
            to return to this task again.</p>

            <hr>
            <h4 class="text-left">Toolbar Icons:</h4>            
            <table>
                <tr>
                    <th>Icon</th>
                    <th>Description</th>
                </tr>
                <tr class="border-bottom">
                    <td><img src="/images/help/pan.png" title="Pan Tool" alt="Pan Tool" /></td>
                    <td class="pl-4 text-left">
                        <strong>Pan</strong>: Drag the page using the mouse/touch.
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td><img src="/images/help/zoom-out.png" title="Zoom Out" alt="Zoom Out" /></td>
                    <td class="pl-4 text-left">
                        <strong>Zoom Out</strong>: Reduce the page magnification.
                    </td>
                </tr>  
                <tr class="border-bottom">
                    <td><img src="/images/help/zoom-in.png" title="Zoom In" alt="Zoom In" /></td>
                    <td class="pl-4 text-left">
                        <strong>Zoom In</strong>: Increase the page magnification.
                    </td>
                </tr>  
                <tr class="border-bottom">
                    <td><img src="/images/help/page-view.png" title="Page View" alt="Page View" /></td>
                    <td class="pl-4 text-left">
                        <strong>Page View</strong>: Toggle between Fit Page (show entire page) and Fit Width.
                    </td>
                </tr> 
                <tr class="border-bottom">
                    <td><img src="/images/help/drawing.png" title="Drawing" alt="Drawing" /></td>
                    <td class="pl-4 text-left">
                        <strong>Freehand Drawing</strong>: Draw freehand, like with a pen. You can change the color and thickness.
                    </td>
                </tr> 
                <tr class="border-bottom">
                    <td><img src="/images/help/eraser.png" title="Eraser" alt="Eraser" /></td>
                    <td class="pl-4 text-left">
                        <strong>Eraser</strong>: Erase portions of a Freehand Drawing.
                    </td>
                </tr>  
                <tr class="border-bottom">
                    <td><img src="/images/help/text.png" title="Text" alt="Text" /></td>
                    <td class="pl-4 text-left">
                        <strong>Text</strong>: Place typed text anywhere on the page. You can change the font size and color.
                    </td>
                </tr> 
                <tr class="border-bottom">
                    <td><img src="/images/help/line.png" title="Line" alt="Line" /></td>
                    <td class="pl-4 text-left">
                        <strong>Line</strong>: Draw a straight line. You can change the color and thickness.
                    </td>
                </tr>                  
                <tr class="border-bottom">
                    <td><img src="/images/help/arrow.png" title="Arrow" alt="Arrow" /></td>
                    <td class="pl-4 text-left">
                        <strong>Arrow</strong>: Draw a line with an arrow at the end. You can change the color and thickness.
                    </td>
                </tr>
                <tr class="border-bottom">
                    <td><img src="/images/help/rectangle.png" title="Rectangle" alt="Rectangle" /></td>
                    <td class="pl-4 text-left">
                        <strong>Rectangle</strong>: Draw a rectangle/sqaure.  You can add a fill color.
                    </td>
                </tr>    
                <tr class="border-bottom">
                    <td><img src="/images/help/ellipse.png" title="Ellipse" alt="Ellipse" /></td>
                    <td class="pl-4 text-left">
                        <strong>Ellipse/Circle</strong>: Draw a ellispe/circle.  You can add a fill color.
                    </td>
                </tr>                                                                    
                <tr>
                    <td><img src="/images/help/formula.png" title="Formula Builder" alt="Formula Builder" /></td>
                    <td class="pl-4 text-left">
                        <strong>Formula Builder</strong>: Create math expressions, equations and formulas.
                    </td>
                </tr>                                                                                                                
            </table>
            <hr>
            <p class="text-left">You may duplicate objects by first selecting them, and then using the <strong><em>Ctrl-D</em></strong> key combination to create a new copy.</p>


            <hr>
            <b-button class="mt-3" @click="$bvModal.hide('help-modal')">Close</b-button>                 
        </b-modal>         

        <div class="row flex-fill">
            <div id="webviewer"></div>
        </div>
  </div>
</template> 

<script>
import PSPDFKit from "pspdfkit";
import MathLive from 'mathlive';
import html2canvas from 'html2canvas';
//import * as htmlToImage from 'html-to-image';
import Event from "../../services/event.js";
import '../../../node_modules/vuejs-dialog/dist/vuejs-dialog.min.css';

let availableTasks = 
    [
        {
            "taskId": 2,
            "taskGrade": "Grade 2",
            "taskName": "Favorite Shapes"
        },
        {
            "taskId": 4,
            "taskGrade": "Grade 4",
            "taskName": "David's Robot"
        },
        {
            "taskId": 6,
            "taskGrade": "Grade 6",
            "taskName": "Awards Dinner"
        },
        {
            "taskId": 9,
            "taskGrade": "Algebra I",
            "taskName": "Arguing Algebra"
        }                                                
    ];

let psPdfKitInstance = null;
let psPdfKitCopiedAnnotation = null;
let psPdfKitContinueAnnotation = null;

export default {
    name: 'Task',
    data() {
        return {
            examStudentTaskId: null,
            task: null,
            showActionButtons: false,   
            pageDirty: true,    
            mf: null,
            mfValue: '',
            webViewer: null,
            crosshairMode: false,
            formulaPngData: null,
            hasTouch: false,
            mathCanvas: null,
            savedAnnotations: null,
        }
    },
    mounted() {  
        //document.addEventListener('mousemove', this.onMouseMove, true);  
        document.addEventListener('touchstart', this.onTouchStart, true);  
        this.examStudentTaskId = this.$route.params.examStudentTaskId;    

        this.$root.$on('bv::modal::show', (bvModalEvent, modalId) => {
            if (modalId == 'formula-modal') {
                this.mf = MathLive.makeMathField('formula', {
                    virtualKeyboardMode: 'onfocus',
                    //fontsDirectory: '../fonts',
                    fontsDirectory: process.env.VUE_APP_MATHLIVE_FONTSURL,
                    onContentDidChange: (mf) => {
                        this.mfValue = mf.getValue();
                    }
                });   
            }
        });
        this.$root.$on('bv::modal::shown', (bvModalEvent, modalId) => {      
            if (modalId == 'formula-modal')
                this.mf.focus();
        });  
        Event.$on("show-formula-modal", () => {
            this.$bvModal.show('formula-modal');
        });  

        // Check for saved annotations
        const savedAnnotationJson = localStorage.getItem('task-' + this.examStudentTaskId);    
        if (savedAnnotationJson != null) {
            let self = this;
            let message = {
                title: 'Unsubmitted Work Detected',
                body: 'The system has detected unfinished/unsubmitted work for this task.  This can be caused if you refreshed you browser before submitting the task.<br><br><strong>Do you wish to load this unsubmitted work or start over with a blank task?</strong>.<br><br>Selecting Start Over will delete your existing work.'
            };        
            let options = {
                okText: 'Load Unsubmitted Work',
                cancelText: 'Start Over',
                html: true,
                customClass: 'unsubmitted-work'
            };                        
            this.$dialog.confirm(message, options)                    
            .then(() => {
                self.savedAnnotations = JSON.parse(savedAnnotationJson);
                self.getTask();                
            })
            .catch(() => {
                localStorage.removeItem('task-' + this.examStudentTaskId);
                self.getTask();
            });
        } 
        else {
            this.getTask();
        }         
    },
    beforeCreate: function() {
        document.body.className = 'task';
    },
    beforeDestroy() {
        document.querySelector('body').classList.remove('task');
    },    
    methods: {
        getTask() {
            let selectedTask = availableTasks.find(x => x.taskId == this.examStudentTaskId);
            this.task = selectedTask;
            this.psPdfKitSetup();
        },
        onTouchStart() {
            this.hasTouch = true;
            document.removeEventListener('touchstart', this.onTouchStart, true);
        },
        resetFormula() {
            this.mf = null;
            this.mfValue = '';
            document.getElementById('formula').innerHTML = ''; 
            document.getElementById('formula').style.fontSize = '200%'; 
            document.getElementById('formula-error').style.display = 'none';
            document.getElementById('formula-modal').style.top = '0';
        }, 
        closeFormula() {      
            this.$bvModal.hide('formula-modal');
            this.resetFormula(); 
        },
        showFormulaModal() {
            this.$bvModal.show('formula-modal');
        }, 
        showHelpModal() {
            this.$bvModal.show('help-modal');
        },  
        saveImageToPage(point) {
            const self = this;
            let formulaImageId = 'formula-image-' + Math.floor(Math.random() * 1001);
            const div = document.createElement('div');
            const img = document.createElement('img');
            img.id = formulaImageId;

            img.onload = () => {
                img.width = Math.floor(img.naturalWidth / 3);
                img.height = Math.floor(img.naturalHeight / 3);

                div.appendChild(img);
                div.style.position = 'absolute';
                div.style.top = '-500px';
                div.style.left = '-500px';
                document.body.appendChild(div);   

                psPdfKitInstance.createAttachment(dataURItoBlob(this.formulaPngData))
                    .then(function(imageAttachmentId) {
                        const annotation = new PSPDFKit.Annotations.ImageAnnotation({
                            pageIndex: psPdfKitInstance.viewState.currentPageIndex,
                            contentType: "image/png",
                            imageAttachmentId: imageAttachmentId,
                            boundingBox: new PSPDFKit.Geometry.Rect({
                                left: point.x,
                                top: point.y,
                                width: img.width,
                                height: img.height,
                            }),
                        }); 
                        psPdfKitInstance.create(annotation)
                            .then(function(createdAnnotation) {
                                psPdfKitInstance.contentDocument.body.style.cursor = "auto";
                                self.crosshairMode = false;
                                self.formulaPngData = null;
                                div.remove();
                            });                                        
                    });                
            };

            img.src = this.formulaPngData;  
        },        
        psPdfKitSetup() {
            const self=this;
            const annotationPresets = psPdfKitBuildPresets();  

            PSPDFKit.load({
                    annotationPresets,
                    container: '#webviewer',
                    document: `/pdfs/${self.examStudentTaskId}.pdf`,
                    instantJSON: self.savedAnnotations,
                    styleSheets: ['/css/webviewer-custom.css'],
                    licenseKey: process.env.VUE_APP_PSPDFKIT_LICENSE,
                    baseUrl: process.env.VUE_APP_PSPDFKIT_BASEURL, 
                    locale: "en"                       
                })
                .then(instance => {
                    psPdfKitInstance = instance;

                    instance.setViewState(viewState => (
                        viewState.set("sidebarMode", null),
                        viewState.set("interactionMode", null)
                    ));                   

                    // Update the Toolbar
                    instance.setToolbarItems(items => {
                        items.splice(0, 4);
                        items.splice(8, 2);
                        items.splice(9, 4);
                        items.splice(14, 6);
                        // console.log(items);

                        const formulaBtn = {
                            type: "custom",
                            id: "formula-button",
                            title: "Expression, Equation & Formula Builder",
                            icon: "/images/icons/formula-fx.png",
                            onPress: event => {
                                instance.setViewState(viewState => (
                                    viewState.set("interactionMode", null)
                                ));                                
                                this.showFormulaModal();
                            }
                        };
                        items.push(formulaBtn);

                        const helpBtn = {
                            type: "custom",
                            id: "help-button",
                            title: "Help",
                            icon: "/images/icons/help.png",
                            onPress: event => {
                                instance.setViewState(viewState => (
                                    viewState.set("interactionMode", null)
                                ));                                
                                this.showHelpModal();
                            }
                        };
                        items.push(helpBtn);

                        return items;
                    });

                    instance.addEventListener("annotations.didSave", async () => {
                        instance.exportInstantJSON().then(function (instantJSON) {
                            localStorage.setItem('task-' + self.examStudentTaskId, JSON.stringify(instantJSON))
                        });
                    });                    

                    instance.addEventListener("page.press", (event) => {
                        if (this.crosshairMode) {
                            this.saveImageToPage(event.point);
                            this.resetFormula();                             
                        }
                    });

                    instance.contentDocument.addEventListener("keydown", keyDownHandler, { capture: true });

                    // Don't reset the selected annotation for shapes and text

                    let interactionModesToReset = [ PSPDFKit.InteractionMode.SHAPE_LINE, 
                                                    PSPDFKit.InteractionMode.SHAPE_RECTANGLE, 
                                                    PSPDFKit.InteractionMode.SHAPE_ELLIPSE, 
                                                    PSPDFKit.InteractionMode.TEXT ];
                    instance.addEventListener("annotations.create", annotations => {
                        if (annotations.size === 1 && 
                            (annotations.get(0) instanceof PSPDFKit.Annotations.LineAnnotation ||
                             annotations.get(0) instanceof PSPDFKit.Annotations.RectangleAnnotation ||
                             annotations.get(0) instanceof PSPDFKit.Annotations.EllipseAnnotation ||
                             annotations.get(0) instanceof PSPDFKit.Annotations.TextAnnotation
                            ) && 
                            interactionModesToReset.includes(instance.viewState.interactionMode)) 
                        {
                            psPdfKitContinueAnnotation = annotations.get(0);
                        }
                    });

                    instance.addEventListener("annotationSelection.change", (annotation) => {  
                        let interactionMode = null;
                        if (annotation === null && psPdfKitContinueAnnotation) {
                            if (psPdfKitContinueAnnotation instanceof PSPDFKit.Annotations.LineAnnotation) {
                                interactionMode = PSPDFKit.InteractionMode.SHAPE_LINE;
                                instance.setCurrentAnnotationPreset('line');
                            }
                            else if (psPdfKitContinueAnnotation instanceof PSPDFKit.Annotations.RectangleAnnotation) {
                                interactionMode = PSPDFKit.InteractionMode.SHAPE_RECTANGLE;
                                instance.setCurrentAnnotationPreset('rectangle');
                            }
                            else if (psPdfKitContinueAnnotation instanceof PSPDFKit.Annotations.EllipseAnnotation) {
                                interactionMode = PSPDFKit.InteractionMode.SHAPE_ELLIPSE;
                                instance.setCurrentAnnotationPreset('ellipse');
                            }
                            else if (psPdfKitContinueAnnotation instanceof PSPDFKit.Annotations.TextAnnotation) {
                                interactionMode = PSPDFKit.InteractionMode.TEXT;
                                instance.setCurrentAnnotationPreset('text');
                            }                            

                            instance.setViewState(state =>
                                state.set("interactionMode", interactionMode)
                            );
                        } 
                        psPdfKitContinueAnnotation = null;
                    });


                    // Upload the PDF
                    document.getElementById('submit-pdf').onclick = e => {
                        let message = {
                            title: 'Submit Your Task?',
                            body: 'Only submit your task when you are completely finished.  You may not return to this task later.'
                        };        
                        let options = {
                            okText: 'Submit Task',
                            cancelText: 'Keep Working',
                            loader: true
                        }                        
                        this.$dialog.confirm(message, options)
                        .then(function (dialog) {
                            self.pageDirty = false;
                            localStorage.removeItem('task-' + self.examStudentTaskId);
                            setTimeout(function() { 
                                dialog.loading(false); 
                                dialog.close(); 
                                self.$router.push({ name: 'Home' }); 
                            }, 1000);                                                      
                        })
                        .catch(function () {
                            return false;
                        });                        
                    }

                    // Insert the Formula
                    document.getElementById('insert-formula').onclick = e => {               
                        if (this.mfValue == '') {
                            document.getElementById('formula-error').style.display = 'block';
                            this.mf.$focus();
                        }       
                        else 
                        {
                            document.getElementById('formula-modal').style.top = '-1000px';
                            document.getElementById('formula').style.fontSize = '500%';
                            var element = document.getElementById('formula').querySelector('.ML__mathlive');
                            html2canvas(element, { backgroundColor: null })
                                .then((canvas) => {
                                    this.formulaPngData = canvas.toDataURL("image/png");
                                    this.$bvModal.hide('formula-modal');
                                    instance.contentDocument.body.style.cursor = "crosshair";  
                                    this.crosshairMode = true;                                      
                            });
                        }
                    };   
                    
                    this.showActionButtons = true;                           
                })
                .catch(error => {
                    console.error(error.message);
                });            
        }         
    },
    beforeRouteLeave (to, from, next) {
        const self=this;
        if (this.pageDirty) {
            let message = {
                title: 'Leaving Page Warning',
                body: 'If you leave this page, your changes will not be saved.  Are you sure you want to proceed?'
            };        
            let options = {
                okText: 'Leave Page',
                cancelText: 'Keep Working'
            }
            this.$dialog.confirm(message, options)
            .then(function () {
                localStorage.removeItem('task-' + self.examStudentTaskId);
                next();
            })
            .catch(function () {
                next(false);
            });
        }
        else {
            next();
        }
    }     
}

function keyDownHandler(event) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        // Copy the annotation
        psPdfKitCopiedAnnotation = psPdfKitInstance.getSelectedAnnotation();
    }

    if ((event.ctrlKey || event.metaKey) && event.key === 'v' && psPdfKitCopiedAnnotation) {
        // Paste (duplicate) the annotation
        duplicateAnnotation(psPdfKitCopiedAnnotation);
    }  

    if ((event.ctrlKey || event.metaKey) && (event.key === 'd' || event.key == 'D')) {
        // duplicate the annotation
        event.cancelBubble = true;
        event.preventDefault();
        event.stopImmediatePropagation();
        duplicateAnnotation(psPdfKitInstance.getSelectedAnnotation());
    }         
}

function duplicateAnnotation(annotation) {
    // See https://github.com/PSPDFKit/pspdfkit-web-examples-catalog/blob/master/examples/11-tooltips/index.js
    if (annotation instanceof PSPDFKit.Annotations.MarkupAnnotation) {
        return [];
    }
    const offset = 50;
    const offsetTranslation = new PSPDFKit.Geometry.Point({
        x: offset,
        y: offset
    });
    const newBoundingBox = annotation.boundingBox.translate(
        offsetTranslation
    );

    let duplicatedAnnotation = annotation
    .set("id", uuid())
    .set("boundingBox", newBoundingBox);

    if (duplicatedAnnotation instanceof PSPDFKit.Annotations.InkAnnotation) {
        duplicatedAnnotation = duplicatedAnnotation.set(
            "lines",
            duplicatedAnnotation.lines.map(line => {
            return line.map(point => point.translate(offsetTranslation));
        })
    );
    }

    if (duplicatedAnnotation instanceof PSPDFKit.Annotations.LineAnnotation) {
        duplicatedAnnotation = duplicatedAnnotation
            .set(
            "startPoint",
            duplicatedAnnotation.startPoint.translate(offsetTranslation)
            )
            .set(
            "endPoint",
            duplicatedAnnotation.endPoint.translate(offsetTranslation)
            );
    }

    psPdfKitInstance.create(duplicatedAnnotation);
    psPdfKitInstance.setSelectedAnnotation(duplicatedAnnotation);
}

function psPdfKitBuildPresets() {
    let annotationPresets = PSPDFKit.defaultAnnotationPresets;
    const colorBlack = new PSPDFKit.Color({ r: 0, g: 0, b: 0 });
    annotationPresets.text = {
        ...annotationPresets.text,
        fontSize: 12
    };
    annotationPresets.ink = {
        ...annotationPresets.ink,
        strokeColor: colorBlack,
        lineWidth: 2
    }; 
    annotationPresets.line = {
        ...annotationPresets.line,
        strokeColor: colorBlack,
        strokeWidth: 3
    };
    annotationPresets.rectangle = {
        ...annotationPresets.rectangle,
        strokeColor: colorBlack,
        strokeWidth: 3
    };
    annotationPresets.ellipse = {
        ...annotationPresets.ellipse,
        strokeColor: colorBlack,
        strokeWidth: 3
    };
    annotationPresets.polygon = {
        ...annotationPresets.polygon,
        strokeColor: colorBlack,
        strokeWidth: 3
    }; 
    annotationPresets.arrow = {
        ...annotationPresets.arrow,
        strokeColor: colorBlack,
        strokeWidth: 3
    }; 

    return annotationPresets;
}



function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    var blob = new Blob([ab], {type: mimeString});
    return blob;
}

function uuid() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}  
</script>

<style scoped>
body {
    overflow: hidden !important;
}
.min-100 {
    min-height: 100vh;
}
.navbar {
    margin: 0 -15px
}
.flex-fill {
    flex: 1 1 auto;
}
#taskPage {
    overflow: hidden;
}
#webviewer {
  width: 100%;
}
#formula {
  font-size: 200%;
}
</style>

<style>
.ML__keyboard.is-visible {
    z-index: 99999 !important;
}
.ML__keyboard .keyboard-toolbar .right {
    display: none !important;
}

.ML__mathlive {
  padding: 10px;
  color: black !important;
}
.dg-btn--ok {
    background-color: #17a2b8;
    border: 2px solid #17a2b8;
    color: white;
}
.dg-title {
    font-size: 1.5rem;
}
.dg-content {
    font-size: 1.1rem;
}
.dg-btn-loader .dg-circle {
    background-color: white !important;
}
#formula-error {
  font-weight: bold;
  font-size: 90%;
  color: #dc3545;
  margin-top: -10px;
  display: none;
}
@media (min-width: 576px) {
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
}
</style>